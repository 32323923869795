import axios from 'axios'

const extractData = (res) => {
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}
const extractDataByStatus = (res) => {
    const { data } = res
    if (res.status === 200 && data) return data
    return []
}
function updateChildFieldById(obj, data, fieldToUpdate) {
    // Base case: if the object has an id and matches the provided id, update the field
    if (obj.key && data[obj.key]) {
        if(Array.isArray(fieldToUpdate)){
            fieldToUpdate.forEach((el,idx)=>{
                obj[el] =  data[obj.key][el];
            })
        }else{
            obj[fieldToUpdate] = data[obj.key][fieldToUpdate];
        }
    }

    // Recursive case: if the object has children, recursively call the function on each child
    if (obj.children && obj.children.length > 0) {
        for (var i = 0; i < obj.children.length; i++) {
            updateChildFieldById(obj.children[i], data, fieldToUpdate);
        }
    }
}
export const arrayProxy = new Proxy(Array, {
   construct(target,[args]) {
       let index = {}
       return new Proxy(new target(...args),{
           get(arr,prop){
               switch (prop){
                   case 'getData':
                       return  ()=> index
                   case 'getDataStates':
                       return  ()=> {
                           const newIndex = {}
                           Object.keys(index).forEach(el=> {
                               if (typeof index[el] == 'object'
                                   && (!index[el].show || !index[el].showText || index[el].any|| index[el].closed)
                               ) {
                                   newIndex[el] = {
                                       id: index[el].id,
                                       key: index[el].key,
                                       show: index[el].show,
                                       textShow: index[el].showText,
                                       any: index[el].any,
                                       showInSideBar: index[el].showInSideBar,
                                       closed: index[el].closed,
                                       level:index[el].level
                                   }
                               }
                           })
                           return newIndex
                       }
                   case 'updateDataStates':
                       return  (data)=> {
                           updateChildFieldById(arr[0], data, ['show', 'textShow'])
                           Object.keys(data).forEach(el => {
                               if (typeof index[el] === 'object') {
                                   index[el] = {
                                       ...index[el],
                                       show: data[el].show,
                                       textShow: data[el].showText,
                                       any: data[el].any,
                                       showInSideBar: data[el].showInSideBar,
                                       closed: data[el].closed,
                                   }
                               }
                            })
                       }
                   case 'updateIndex':
                       return (data) => {
                           index = data || {}
                       }
                   case 'addItem':
                       return (data) => {
                           index[data.key] = {
                               show: data.showState,
                               textShow: data.showText,
                               any:false,
                               ...data,
                               showInSideBar: false,
                               closed:false
                           }
                           index[data.id] = data.key
                       }
                   case 'getKeyById':
                       return (id) => index[id] && index[index[id]]
                   case 'checkIsDisableItem':
                       return (key) => index[key]?.show === false
                   case 'checkIsHideAnyItem':
                       return (key) => index[key]?.any
                   case 'checkIsHideItem':
                       return (key) => !index[key]?.isDisabled && index[key]?.show === false
                   case 'updateShowAnyById':
                       return (key,bool) => index[key].any = bool
                   case 'updateShowById':
                       return (key,bool) => {
                           if(index[key]) index[key].show = bool
                       }
                   case 'checkIsHideItemText':
                       return (key) => index[key]?.textShow === false
                   case 'getBykey':
                       return (key) => index[key]
                   case 'updateTextShowById':
                       return (key,bool) => {
                           if(index[key]) index[key].textShow = bool
                       }
                   case 'updateId':
                       return (key,bool) => {if(index[key]) index[key].showInSideBar = bool}
                   case 'removeId':
                       return key => {if(index[key]) delete index[key].showInSideBar}
                   case 'findById':
                       return key=> index[key]?.showInSideBar
                   case 'toggleOpen':
                       return (key, bool) => {
                           index[key].closed = !bool
                           return index;
                       }
                   case 'checkIsClose':
                       return (key) => {
                           return index[key].closed;
                       }
                   case 'hasElement':
                       return (key) => {
                           return !!index[key];
                       }
                   default:
                       return  arr[prop]
               }
           }
       })
   }
});
export const sanitizeResult = (data) => {
    const parent ={
        "id":999,
        "title":"Պայմանական նշաններ",
        "name":"Պայմանական նշաններ",
        "key":"Պայմանական նշաններ",
        "description":null,
        "iconId":null,
        "iconPath":"https://www.svgrepo.com/show/57016/flag.svg",
        "color":"#506B90",
        "languageId":1,
        "isLegend":true,
        "children":[],
        "attributes":null,
        "show":true,
        "textShow":true,
        "level":1
    }
    const mockLegendData = new arrayProxy([])
    mockLegendData.addItem(parent)
    mockLegendData.push(parent)
    let layerHideCount = 0
    let layerTextHideCount = 0
    const layers = data.reduce((acum, el, idx, arr) => {
        const newItem = {
            id:el.id,
            title:el.name,
            children:[],
            isParent:true,
            checked:false,
            iconPath:el.icon,
            color:'#506B90',
            show:true,
            textShow:true,
            isDisabled:false,
            key:el.key,
            level:2
        }
        mockLegendData.addItem(el)
        if (el.subLegends?.length > 0){
            let subLayerHideCount = 0
            let subLayerTextHideCount = 0
            newItem.children = el.subLegends.reduce((acum, item) => {
                if(!item.showState) subLayerHideCount++
                if(!item.showText) subLayerTextHideCount++
                acum.push({
                    id:item.id,
                    title:item.name,
                    checked:false,
                    iconPath:item.icon,
                    color:'#506B90',
                    show: item.showState,
                    textShow: item.showText,
                    isDisabled:item.isDisabled,
                    showInLegend:item.showInLegend && item.showInMapAlways,
                    key:item.key,
                    level:3
                })
                mockLegendData.addItem(item)
                if(subLayerHideCount > 0){
                    mockLegendData.updateShowAnyById(el.key,true)
                }
                if(subLayerHideCount === el.subLegends.length) {
                    newItem.show = false
                    mockLegendData.updateShowById(el.key,false)
                    mockLegendData.toggleOpen(el.key,false)
                    layerHideCount++
                }
                if(subLayerTextHideCount === el.subLegends.length) {
                    newItem.textShow = false
                    // mockLegendData.updateShowById(el.key,false)
                    // mockLegendData.toggleOpen(el.key,false)
                    layerTextHideCount++
                }
                return acum
            }, [])
        }
        acum.push(newItem)
        return acum
    }, [])
    mockLegendData[0].children = layers
    if(layerHideCount === mockLegendData[0].children.length){
        mockLegendData[0].show = false
        mockLegendData.updateShowById(mockLegendData[0].key,false)
    }
    if(layerTextHideCount === mockLegendData[0].children.length){
        mockLegendData[0].textShow = false
        // mockLegendData.updateShowById(mockLegendData[0].key,false)
    }
    return mockLegendData
}

// export const sanitizeResult = (data,activeLegends) => {
//     const children = {}
//     const parent ={
//         "id":999,
//         "title":"Պայմանական նշաններ",
//         "name":"Պայմանական նշաններ",
//         "key":"Պայմանական նշաններ",
//         "description":null,
//         "iconId":null,
//         "iconPath":"https://www.svgrepo.com/show/57016/flag.svg",
//         "color":"#506B90",
//         "languageId":1,
//         "isLegend":true,
//         "children":[],
//         "attributes":null,
//         "show":true,
//         "textShow":true,
//         "level":1
//     }
//     const mockLegendData = new arrayProxy([])
//           mockLegendData.addItem(parent)
//           mockLegendData.push(parent)
//     let layerHideCount = 0
//     const layers =  data.reduce((acum,el,idx,arr)=>{
//             if(!el.parentId){
//                 acum.push({
//                     id:el.id,
//                     title:el.name,
//                     children:[],
//                     isParent:true,
//                     checked:false,
//                     iconPath:el.icon,
//                     color:'#506B90',
//                     show:true,
//                     textShow:true,
//                     isDisabled:false,
//                     key:el.key,
//                     level:2
//                 })
//                 el.level = 2
//                 mockLegendData.addItem(el)
//
//             }else{
//                 if(el.showInLegend && el.showInMapAlways) {
//                     if (!Array.isArray(children[el.parentId])) children[el.parentId] = []
//                     children[el.parentId].push(el)
//                 }
//             }
//             return acum
//
//     },[])
//     .map(el=>{
//         if(children[el.id]){
//             let subLayerHideCount = 0
//             children[el.id].forEach((item,idx)=>{
//                 if(!item.showState) subLayerHideCount++
//                 if(item.showInMapAlways && item.showInLegend && activeLegends?.includes(item.id)) {
//                     el.children.push({
//                         id:item.id,
//                         title:item.name,
//                         checked:false,
//                         iconPath:item.icon,
//                         color:'#506B90',
//                         show: item.showState,
//                         textShow: item.showText,
//                         isDisabled:item.isDisabled,
//                         showInLegend:item.showInLegend && item.showInMapAlways,
//                         key:item.key,
//                         level:3
//                     })
//                     item.level = 3
//                     mockLegendData.addItem(item)
//                 }
//
//             })
//             if(subLayerHideCount > 0){
//                 mockLegendData.updateShowAnyById(el.key,true)
//             }
//             if(subLayerHideCount === children[el.id].length) {
//                 el.show = false
//                 mockLegendData.updateShowById(el.key,false)
//                 mockLegendData.toggleOpen(el.key,false)
//                 layerHideCount++
//             }
//         }
//         return el
//     })
//     .filter(el=>el.children.length > 0)
//     mockLegendData[0].children = layers
//     if(layerHideCount === mockLegendData[0].children.length){
//         mockLegendData[0].show = false
//         mockLegendData.updateShowById(mockLegendData[0].key,false)
//     }
//     return mockLegendData
// }
const legendDataProviderAPIRequest = (body) => {
    return axios
        .post(
            `${process.env.REACT_APP_GIS_URL}/Legends/DataProvider`,
            body
        )
        .then(extractDataByStatus)
}
const legendAPIRequest = () => {
    return axios
        .get(
            `${process.env.REACT_APP_GIS_URL}/Legends`
        )
        .then(extractDataByStatus)
}
const subLegendAPIRequest = () => {
    return axios
        .get(
            `${process.env.REACT_APP_GIS_URL}/SubLegends`
        )
        .then(extractDataByStatus)
}
const activeLegendAPIRequest = (query) => {
    return axios
        .get(
            `${process.env.REACT_APP_GIS_URL}/Metadata/Layers${query}`
        )
        .then(extractDataByStatus)
}



export const getLegendsAPI = (body) => {
    return legendDataProviderAPIRequest(body)
            // .then(async (resp)=>{
            //
            //     const result = [[...resp[0],...resp[1]]]
            //     await activeLegendAPIRequest(query)
            //         .then((res)=> {
            //             result.push(res)
            //         })
            //     return resp
            // })
            .then((resp)=>{
                return {
                    realData:resp,
                    sanitizeResult:sanitizeResult(resp)
                }
            })
            .catch(console.error)
}
// export const getLegendsAPI = (query) => {
//     const legends = legendAPIRequest(query)
//     const subLegends = subLegendAPIRequest(query)
//     return  Promise.all([legends,subLegends])
//             .then(async (resp)=>{
//                 const result = [[...resp[0],...resp[1]]]
//                 await activeLegendAPIRequest(query)
//                     .then((res)=> {
//                         result.push(res)
//                     })
//                 return result
//             })
//             .then((resp)=>{
//                 return {
//                     realData:resp[0],
//                     sanitizeResult:sanitizeResult(resp[0],resp[1])
//                 }
//             })
//             .catch(console.error)
// }


