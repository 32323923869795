import {
    SET_LEGENDS,
    SET_FILTERED_LEGENDS,
    LOADING_LEGENDS,
} from '../constants'
import {getLegendsAPI, sanitizeResult} from "../../service/legends";
import {setFilteredCategories} from "./categories";

export const loadingLegends = () => ({
    type: LOADING_LEGENDS,
})

export const setLegends = (legends) => ({
    type: SET_LEGENDS,
    payload: legends,
})
export const setFilteredLegends = (filteredLegends) => ({
    type: SET_FILTERED_LEGENDS,
    payload: filteredLegends,
})


// export const getLegendsFromAPI = (query) => async (dispatch, getState) => {
//     dispatch(loadingLegends())
//     return getLegendsAPI(query).then((resdata) => {
//         dispatch(setLegends(resdata.realData))
//         dispatch(setFilteredLegends(resdata.sanitizeResult))
//         return resdata.sanitizeResult
//     })
// }
export const getLegendsFromAPI = (query) => async (dispatch, getState) => {
    dispatch(loadingLegends())
    return getLegendsAPI(query).then((resdata) => {
        dispatch(setLegends(resdata.realData))
        dispatch(setFilteredLegends(resdata.sanitizeResult))
        return resdata.sanitizeResult
    })
}
export const dispatchFilteredLegends = (data) => (dispatch,getState) =>{
    dispatch(loadingLegends())
    // dispatch(setFilteredLegends(data))
}
const _toggleLayar = (legends, layerId,subLayerId,key,type,action) => {
    const layer= legends[0]
    let allSubLayerHide = false
    let some = false

    if(!action || action !=='text') {
        const flag = layer.children.filter((item, subLayerIndex) => {
            if (item.id === subLayerId) {
                item.show = !item.show
                legends.updateShowById(item.key,item.show)
                legends.toggleOpen(item.key,item.show)
                item.children.forEach(el => {
                    el.show = item.show
                    legends.updateShowById(el.key,item.show)
                    if(!el.show) some = true
                })
                legends.updateShowAnyById(item.key,some)
            }
            if(item.show) return item
        }).length === 0
        if (flag) allSubLayerHide = true
        if (allSubLayerHide) {
            layer.show = false
            legends.updateShowById(layer.key,false)
            return
        }
        layer.show = true
        legends.updateShowById(layer.key,true)
    }
    else{
        const flag  = layer.children.filter((item, subLayerIndex) => {
            if (item.id === subLayerId) {
                item.textShow = !item.textShow
                item.children.forEach(el => {
                    el.textShow = item.textShow
                    legends.updateTextShowById(el.key,item.textShow)
                })
            }
            legends.updateTextShowById(item.key,item.textShow)
            if(item.textShow) return item
        }).length === 0
        if (flag) allSubLayerHide = true

        if (allSubLayerHide) {
            layer.textShow = false
            legends.updateTextShowById(layer.key,false)
            return
        }
        layer.textShow = true
        legends.updateTextShowById(layer.key,true)
    }
}
const _toggleSubLayar = (legends, layerId,subLayerId,key,type,action) => {
    const layerIndex = legends[0].children.findIndex((x) => x.id === layerId)
    const subLayerIndex = legends[0].children[layerIndex].children.findIndex(el=>el.id === subLayerId)

    const head = legends[0]
    const layer =  head.children[layerIndex]
    const subLayer =  layer.children[subLayerIndex]
    let allSubLayerHide = false
    let allLayerHide = false
    let some = false
    if(!action || action !=='text') {
        subLayer.show = !subLayer.show
        legends.updateShowById(subLayer.key,subLayer.show)
        const flag = layer.children.filter(el=> {
            if(!el.show) some = true
            if(el.show) return el
        }).length === 0
        if (flag) allSubLayerHide = true
        legends.updateShowAnyById(layer.key,some)
        if (allSubLayerHide) {
            layer.show = false
            legends.updateShowById(layer.key,layer.show)
            legends.toggleOpen(layer.key,layer.show)
            if (layer.children.findIndex(el=>el.show) !== -1) allLayerHide = true
            if (!allLayerHide) {
                head.show = false
                legends.updateShowById(head.key,head.show)
            }
            return
        }
        layer.show = true
        legends.updateShowById(layer.key,true)
        legends.toggleOpen(layer.key,true)
        head.show = true
        legends.updateShowById(head.key,true)


    }
    else{
        subLayer.textShow = !subLayer.textShow
        legends.updateTextShowById(subLayer.key,subLayer.textShow)
        if (layer.children.findIndex(el=>el.textShow) === -1) allSubLayerHide = true
        if (allSubLayerHide) {
            layer.textShow = false
            legends.updateTextShowById(layer.key,false)
            if (head.children.findIndex(el=>el.textShow) === -1) allLayerHide = true
            if (allLayerHide) {
                head.textShow = false
                legends.updateTextShowById(head.key,false)
            }
            return
        }
        layer.textShow = true
        legends.updateTextShowById(layer.key,layer.textShow)
        legends[0].textShow = true
        legends.updateTextShowById(head.key,head.textShow)
    }

}
const _toggleAllLayar = (legends, layerId,subLayerId,key,type,action) => {
    const layer = legends
    if(!action || action !=='text') {
        layer.forEach((item, subLayerIndex) => {
            let some = false
            item.show = !item.show
            legends.updateShowById(item.key,item.show)
                item.children.forEach(el => {
                    el.show = item.show

                    legends.toggleOpen(el.key,item.show)
                    legends.updateShowById(el.key,item.show)
                        el.children.forEach(subel => {
                            subel.show = item.show
                            if(!subel.show) some = true
                            legends.updateShowById(subel.key,item.show)
                        })
                    legends.updateShowAnyById(el.key,some)
                })
        })
    }
    else{
        layer.filter((item, subLayerIndex) => {
            item.textShow = !item.textShow
            legends.updateTextShowById(item.key,item.textShow)
                item.children.forEach(el => {
                    el.textShow = item.textShow
                    legends.updateTextShowById(el.key,item.textShow)
                        el.children.forEach(subel => {
                            subel.textShow = item.textShow
                            legends.updateTextShowById(subel.key,item.textShow)
                        })
                })
        })
    }
}
export const updateLegendToggle =    (layerId,subLayerId,key,type,action) => (dispatch, getState) => {
    const state = getState()
    const legends = state.legends.filteredData

    if (type === "all-layers") {
        _toggleAllLayar(legends,layerId,subLayerId,key,type,action)
    }
    else if (type === "layer") {
         _toggleLayar(legends,layerId,subLayerId,key,type,action)
    }
    else if (type === "sub-layer") {
        _toggleSubLayar(legends,layerId,subLayerId,key,type,action)
    }
    // dispatch(setLegends(legends))
    dispatch(setFilteredLegends(legends))
}
