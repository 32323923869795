import React, {  useEffect, useRef } from 'react';
import { connect } from "react-redux";
import {
    getStickyNotesMarkersLoadingState,
    getStickyNotesMarkersState,
    getStickyNotesClickedState,
    getRulerClickedState,
} from "../../../store/selectors";

import {
    setStickyNotesClickedState,
    setRulerClickedState,
    setLineDrawToolboxState,
    setCommentToolboxState,
    setEraserClickedState,
    setDrawerToolboxClickedState,
    setPolygonDrawToolboxColors,
    setPolygonDrawToolboxState,
    setTextToolboxState
} from "../../../store/actions/painterStart";
import {
    setStickyNotesMarkersLoadingState, setStickyNotesMarkersState
} from "../../../store/actions/mapStateAction";

import {
    changeCursorIcon,
    CURSOR_TYPE,
    generateUniqueId
} from "../../../shared/mockData";

import StickyNotesIcon from "../../../assets/imgs/PaintBar/sticky-notes-icon.svg";
import "./style.css";
import { isMobile } from "react-device-detect";

const StickyNotes = (props) => {
    const {
        getStickyNotesClickedState,
        setStickyNotesClickedState,
        getStickyNotesMarkersState,
        setStickyNotesMarkersState,
        getStickyNotesMarkersLoadingState,
        setStickyNotesMarkersLoadingState,
        setCommentToolboxState,
        setRulerClickedState,
        setLineDrawToolboxState,
        setEraserClickedState,
        setPolygonDrawToolboxState,
        setDrawerToolboxClickedState,
        setTextToolboxState,
        map
    } = props;

    const markersCopy = useRef(null)
    const stickyNotesClickHandler = useRef(null);
    const currentMarkerRef = useRef(null);
    const mapIsMoving = useRef(false);
    const isAnyMarkerMoving = useRef(false);

    if (isMobile) {
        map.on('moveend', () => { mapIsMoving.current = false; });
        map.on('dragstart', () => { mapIsMoving.current = true; });
    }

    const addNoteToState = (note) => {
        setStickyNotesMarkersState([...markersCopy.current, note])
    }

    const handleMapClick = (e) => {
        e.originalEvent.preventDefault();
        e.originalEvent.stopPropagation();
        if (isMobile && (mapIsMoving.current || isAnyMarkerMoving.current)) {
            return;
        }
        const screenCoordinates = e.point;
        const uniqueId = generateUniqueId();
        const markerWidth = 180;
        const offset = 10;
        let left = screenCoordinates.x - markerWidth / 2;
        let top = screenCoordinates.y;
        if (left > window.innerWidth - markerWidth - offset) {
            left = window.innerWidth - markerWidth - offset;
        }
        if (left < offset) {
            left = offset;
        }
        if (top > window.innerHeight - 370) {
            top = window.innerHeight - 370;
        }
        if (top < offset) {
            top = offset;
        }
        const newNote = {
            id: uniqueId,
            markerWidth: 180,
            markerHeight: 220,
            background: '#FFF8A7',
            markerColor: '#FFF8A7',
            isEdited: true,
            isMaximized: true,
            angle: 0,
            description: '',
            left: left,
            top: top,

        }
        setTimeout(() => setStickyNotesClickedState(false), 100)
        addNoteToState(newNote)
    };


    useEffect(() => {
        if(getStickyNotesClickedState) {
            map.on(`${isMobile ? "touchend" : "click"}`, stickyNotesClickHandler.current);
            setTimeout(() => { changeCursorIcon(CURSOR_TYPE.STICKY_NOTES) }, 50)
        } else {
            if (currentMarkerRef.current && currentMarkerRef.current.getPopup().isOpen()) {
                currentMarkerRef.current.getPopup().remove();
            }
            map.off(`${isMobile ? "touchend" : "click"}`, stickyNotesClickHandler.current);
            changeCursorIcon()
        }
    }, [getStickyNotesClickedState]);

    useEffect(() => {
        if (!getStickyNotesMarkersLoadingState) {
            setStickyNotesMarkersLoadingState(true);
        }
    }, [getStickyNotesMarkersLoadingState]);

    useEffect(() => {
        markersCopy.current = getStickyNotesMarkersState
    }, [getStickyNotesMarkersState])

    useEffect(() => {
        stickyNotesClickHandler.current = handleMapClick;
    }, []);

    const handleIconClick = () => {
        setLineDrawToolboxState(false);
        setRulerClickedState(false);
        setCommentToolboxState(false);
        setDrawerToolboxClickedState(false);
        setEraserClickedState(false);
        setPolygonDrawToolboxState(false);
        setTextToolboxState(false)
        setStickyNotesClickedState(!getStickyNotesClickedState);
    }

    return (
        <>
            <div
                className={`pain_items ${getStickyNotesClickedState ? "button_active" : ""}`}
                onClick={handleIconClick}
            >
                <img src={StickyNotesIcon} alt="" className="icon_img"/>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    getStickyNotesClickedState: getStickyNotesClickedState(state),
    getStickyNotesMarkersState: getStickyNotesMarkersState(state),
    getRulerClickedState: getRulerClickedState(state),
    getStickyNotesMarkersLoadingState: getStickyNotesMarkersLoadingState(state),
});

const mapDispatchToProps = {
    setStickyNotesClickedState,
    setEraserClickedState,
    setRulerClickedState,
    setStickyNotesMarkersState,
    setStickyNotesMarkersLoadingState,
    setLineDrawToolboxState,
    setCommentToolboxState,
    setDrawerToolboxClickedState,
    setPolygonDrawToolboxState,
    setTextToolboxState
};

export default connect(mapStateToProps, mapDispatchToProps)(StickyNotes);

