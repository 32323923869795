import React, {memo, useEffect, useRef, useState} from 'react';
import "./style.css";
import 'react-resizable/css/styles.css';
import mapboxgl from "mapbox-gl";
import {isMobile} from "react-device-detect";
import ReactDOM from "react-dom";
import PopUpTextBox from "./TextToolBox";
import {getCommentToolboxState, getDrawerToolboxClickedState, getEraserClickedState} from "../../../store/selectors";
import {connect} from "react-redux";


const TextBoxMarker = (props) => {
    const {
        description,
        coordinates,
        id,
        currentMarkerRef,
        setCurrentMarkerRef,
        handleDeleteMarker,
        setMarkerInfoParamByID,
        backgroundColor,
        color,
        height,
        width,
        opacity,
        rotation,
        fontSize,
        getEraserClickedState,
        getDrawerToolboxClickedState,
        map,
    } = props
    const markerRef = useRef();

    const handleRenderPopup = (node) => {
        ReactDOM.render(<PopUpTextBox
            markerBackground={backgroundColor}
            coordinates={coordinates}
            markerColor={color}
            id={id}
            markerHeight={height}
            markerWidth={width}
            markerDescription={description}
            markerRotation={rotation}
            markerOpacity={opacity}
            markerFontSize={fontSize}
            markerRef={markerRef}
            handleDelete={handleDelete}
            map={map}
            handleSave={handleSave}
            handleStopDragging={handleStopDragging}
            handleEnableDragging={handleEnableDragging}
            getDrawerToolboxClickedState={getDrawerToolboxClickedState}
            getEraserClickedState={getEraserClickedState}
            currentMarkerRef={currentMarkerRef}/>, node);
    }

    const handleDelete = (id) => {
        markerRef.current.remove();
        handleDeleteMarker(id)
    }

    const handleStopDragging = () => {
        markerRef.current && markerRef.current.setDraggable(false)
        map["dragPan"].disable();
        map.doubleClickZoom.disable();
    }

    const handleEnableDragging = () => {
        markerRef.current && markerRef.current.setDraggable(true)
        map["dragPan"].enable();
    }

    const markerDragEnd = (e) => {
        const coords = e.target._lngLat
        setMarkerInfoParamByID(id, 'coordinates', coords);
    }

    const handleSave = (text, color, backgroundColor, opacity, height, width, rotation, fontSize) => {
        setMarkerInfoParamByID(id, 'id', id);
        setMarkerInfoParamByID(id, 'description', text);
        setMarkerInfoParamByID(id, 'color', color);
        setMarkerInfoParamByID(id, 'backgroundColor', backgroundColor);
        setMarkerInfoParamByID(id, 'opacity', opacity);
        setMarkerInfoParamByID(id, 'height', height);
        setMarkerInfoParamByID(id, 'width', width);
        setMarkerInfoParamByID(id, 'rotation', rotation);
        setMarkerInfoParamByID(id, 'fontSize', fontSize);
    };

    const markerClickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (markerRef.current._isDragging) {
            return;
        }
        if (currentMarkerRef.current !== markerRef.current) {
            setCurrentMarkerRef(markerRef.current);
        }
    }

    useEffect(() => {
        if(getEraserClickedState || getDrawerToolboxClickedState) {
            handleStopDragging()
        } else {
            handleEnableDragging()
        }
    }, [getEraserClickedState, getDrawerToolboxClickedState])

    useEffect(() => {
        const markContainer = document.createElement('div');
        markContainer.id = id;
        handleRenderPopup(markContainer)
        markerRef.current = new mapboxgl.Marker(markContainer)
            .setLngLat(coordinates)
            .setDraggable(true)
            .addTo(map);
        markerRef.current.id = id
        setCurrentMarkerRef(markerRef.current);
        // !isMobile && markerRef.current.getElement().addEventListener('mouseenter', markerOnMouseEnter);
        // !isMobile && markerRef.current.getElement().addEventListener('mouseleave', markerOnMouseLeave);
        markerRef.current.getElement().addEventListener(`${isMobile ? "touchend" : "click"}`, markerClickHandler);
        // markerRef.current.on('dragstart', markerDragStart);
        // markerRef.current.on('drag', markerDragHandler)
        markerRef.current.on('dragend', markerDragEnd);
        // markerRef.current.getPopup().on('open', markerEditPopupOnOpen);
        // markerRef.current.getPopup().on('close', markerEditPopupOnClose);
        return () => {
            markerRef.current.remove();
        }
    }, []);

    return null
};

const mapStateTopProps = (state) => ({
    getEraserClickedState: getEraserClickedState(state),
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state)
});

const mapDispatchToProps = {};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(TextBoxMarker));
