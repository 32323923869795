import { parse, stringify } from "flatted";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { log } from "react-zlib-js";
import ScreenShotIcon from "../../../../assets/imgs/screenshot-icon.svg";
import ScreenShotIconWhite from "../../../../assets/imgs/screenshot-icon-white.svg";
import PresentationIcon from "../../../UI/Presentation";
import ScreenShotNotificationIcon from "../../../../assets/imgs/screenshot-notification-icon.svg";
import {
  createScreenShotAPI,
  setScreenShotSendDataST,
  setScreenShotIntermediateDataST,
  setScreenShotButtonClickST,
  setScreenShotButtonStateST,
  editScreenDataAPI,
} from "../../../../store/actions/mapStateAction";
import {
  getArticlesData,
  getCategoryArticleClickedId,
  getColorPickerShowState,
  getCompassCoordinates,
  getEraserClickedState,
  getFilteredCategoriesData, getFilteredLegendsData,
  getHintsData,
  getLampData,
  getLampModalState, getLampModalStateIndex,
  getLampSelectedId,
  getLampSortedTooltipState, getLessonNavigationData,
  getMapBounceData,
  getMapCenterCoordinatesST, getMapMarkerInfo,
  getMapRequirementData,
  getMapRequirementsData,
  getMapStateSingleData,
  getMapStyledDarkId,
  getMenuData,
  getMenuExpendData,
  getNewAngle,
  getNewRotate,
  getOpenCategoryID,
  getPainterColor,
  getPainterColorData,
  getPainterGeoJsonDataST,
  getPainterSize,
  getPainterSizeData,
  getPainterStartData,
  getQuizPopupData,
  getRulerClickedState,
  getRulerGeoJsonDataST,
  getRulerPopupShowST,
  getCommentToolboxState,
  getCommentToolboxMarkersState,
  getStickyNotesMarkersState,
  getStickyNotesClickedState,
  getScreenShotButtonClickST,
  getScreenShotIntermediateDataST,
  getScreenShotSendDataST,
  getSelectedLessonId,
  getDrawerToolboxClickedState, getSubjectsSettingsData,
  getTimeLineCursorStatusST,
  getTimeLineEventDataST,
  getLineDrawToolboxState,
  getMapboxDrawToolboxGeoJSONState,
  getTimeLineExpendData, getTimeLineHeightST,
  getTimeLineItemsData,
  getTimeLineMoveCountST,
  getTimeLineMovePositionST,
  getTimeLineMoveStatusST,
  getTimeLineMovieDataST,
  getTimeLineZoomIndexST,
  getTimeLineZoomST,
  getTimeTineZoomLevelST, getTopicNavigationData,
  getTopicsData,
  getTopicStaticTitleST, getBaseMapGroupIdST, getBaseMapIsLight,
  getTextToolboxState, getTextToolboxMarkersState,
} from "../../../../store/selectors";
import "./style.css";
import {setLampModalState} from "../../../../store/actions/lamp";
import ScreenShotNotification from "../ScreenShotNotification";
import ScreenShotNotificationPopup from "../ScreenShotNotificationPopup";
import AttachIcon from "../../../../assets/imgs/icons/attachIcon.svg";
import {getPresentationAPI} from "../../../../store/actions/presentationMode";
import {createSlideAPI, editSlideAPI} from "../../../../store/actions/slides";
import html2canvas from 'html2canvas';
import {presentationPostMethod} from "../../../../service/presentationMode";
import {handleCaptureTypeBlob,uploadImage} from "../../../../utils/helpers";
import SaveSlideIcon from "../../../../assets/imgs/icons/saveSlide.svg";
import ArticlePresentationScreenshot from "../../ArticlePresentationScreenshot";
const MapScreenShotButton = (props) => {
  const {
    createScreenShotAPI,
    menuExpend,
    getOpenCategoryID,
    mapRequirement,
    mapRequirements,
    hints,
    quizPopup,
    getCategoryArticleClickedId,
    filteredCategories,
    getLampData,
    getLampSortedTooltipState,
    getLampSelectedId,
    getMapStyledDarkId,
    painterStart,
    getDrawerToolboxClickedState,
    getPainterColor,
    getPainterColorData,
    getPainterSize,
    getPainterSizeData,
    getColorPickerShowState,
    getRulerClickedState,
    getPainterGeoJsonDataST,
    getRulerGeoJsonDataST,
    getCommentToolboxState,
    getStickyNotesClickedState,
    getCommentToolboxMarkersState,
    getStickyNotesMarkersState,
    getTextToolboxState,
    getTextToolboxMarkersState,
    getNewAngle,
    getNewRotate,
    getCompassCoordinates,
    getRulerPopupShowST,
    getTimeLineZoomST,
    getTimeLineZoomIndexST,
    getTimeLineMoveCountST,
    getTimeLineCursorStatusST,
    getTimeLineEventDataST,
    getTimeLineMovePositionST,
    getTimeLineMoveStatusST,
    getTimeTineZoomLevelST,
    timelineExtend,
    getLineDrawToolboxState,
    getMapboxDrawToolboxGeoJSONState,
    getMapCenterCoordinatesST,
    getLineDrawToolboxLinesState,
    setScreenShotSendDataST,
    setScreenShotIntermediateDataST,
    getScreenShotButtonClickST,
    setScreenShotButtonClickST,
    setScreenShotButtonStateST,
    disable = "none",
    selectedLesson,
    articleData,
    menu,
    article = false,
    isReadMoreArticle = false,
    isGalleryMode = false,
    articlePosition = null,
    // closedBlocks = null,
    getTimeLineMovieDataST,
    getScreenShotSendDataST,
    editScreenDataAPI,
    getTopicStaticTitleST,
    getTimeLineHeightST,
    getLampModalState,
    getLampModalStateIndex,
    filteredLegends,
    map,
    getSubjectsSettingsData,
    type,
    createSlideAPI,
    editSlideAPI,
    globalLoading,
    getMapMarkerInfo,
    topicNavigation,
    baseMapGroupIdST,
    baseMapIsLight,
    lessonNavigationData,
    white = false,
    galleryIndex = null,
    galleryTiliIndex = null,
    galleryFullScreen = false,
    articleId,
  } = props;
  const overlay = useRef();
  const contentRef = useRef();
  const params = useLocation();

  let [searchParams] = useSearchParams();
  let topicID = searchParams.get("topicID");
  let isScreenShot = searchParams.get("screenShot");
  let isPresentation = searchParams.get("presentation");
  let screenSlideID = searchParams.get("screenSlide");


  const url = `${params?.pathname}${params?.search}`;
  const handleReturnStatesData = () => {

    return {
      url: url,
      // getLampData: getLampData,
      baseMapGroupIdST: baseMapGroupIdST,
      baseMapIsLight:baseMapIsLight,
      topicTitle: topicNavigation.current?.title,
      topicStaticTitle: topicNavigation.current?.title,
      lessonTitle: lessonNavigationData.current?.title,
      menuExpend: menuExpend,
      categoryOpenID: getOpenCategoryID,
      mapRequirement: mapRequirement,
      mapRequirements: mapRequirements,
      hints: hints,
      quizPopup: quizPopup,
      getCategoryArticleClickedId: getCategoryArticleClickedId,
      filteredCategoriesProxyData: typeof filteredCategories?.getDataStates === 'function' && filteredCategories?.getDataStates(),
      filteredLegendsProxyData: typeof filteredLegends?.getDataStates === 'function' && filteredLegends?.getDataStates(),
      lampDataFiltered: lampDataFiltered(),
      getLampSortedTooltipState: getLampSortedTooltipState,
      getLampSelectedId: getLampSelectedId,
      getMapStyledDarkId: getMapStyledDarkId,
      painterStart: painterStart,
      getRulerClickedState: getRulerClickedState,
      getPainterGeoJsonDataST:
          Object.keys(getPainterGeoJsonDataST).length > 0
              ? getPainterGeoJsonDataST
              : {
                type: "FeatureCollection",
                features: [],
              },
      getRulerGeoJsonDataST: getRulerGeoJsonDataST,
      getCommentToolboxState: getCommentToolboxState,
      getCommentToolboxMarkersState: getCommentToolboxMarkersState,
      getStickyNotesClickedState: getStickyNotesClickedState,
      getStickyNotesMarkersState: getStickyNotesMarkersState,
      getTextToolboxState: getTextToolboxState,
      getTextToolboxMarkersState: getTextToolboxMarkersState,
      getNewAngle: getNewAngle,
      getMapboxDrawToolboxGeoJSONState: getMapboxDrawToolboxGeoJSONState,
      getNewRotate: getNewRotate,
      getLineDrawToolboxState: getLineDrawToolboxState,
      getLineDrawToolboxLinesState: getLineDrawToolboxLinesState,
      getCompassCoordinates: getCompassCoordinates,
      getRulerPopupShowST: getRulerPopupShowST,
      getTimeLineZoomST: getTimeLineZoomST,
      getTimeLineZoomIndexST: getTimeLineZoomIndexST,
      getTimeLineMoveCountST: getTimeLineMoveCountST,
      getTimeLineCursorStatusST: getTimeLineCursorStatusST,
      getTimeLineEventDataST: getTimeLineEventDataST,
      getTimeLineMovePositionST: getTimeLineMovePositionST,
      getTimeLineMoveStatusST: getTimeLineMoveStatusST,
      getTimeTineZoomLevelST: getTimeTineZoomLevelST,
      timelineExtend: timelineExtend,
      getMapCenterCoordinatesST: getBoundsMap(),
      selectedLesson: selectedLesson,
      articleData: articleData,
      markerInfo:getMapMarkerInfo,
      menu: menu,
      isReadMore: isReadMoreArticle,
      articlePosition,
      // closedBlocks,
      isGalleryMode,
      galleryIndex,
      galleryTiliIndex,
      galleryFullScreen,
      articleId,
      getTimeLineMovieDataST: getTimeLineMovieDataST,
      getTimeLineHeightST,
      getLampModalState, getLampModalStateIndex,
      updateSeenDate:getSubjectsSettingsData?.modifiedAt,
    }
  }

  const getBoundsMap = () =>{
    var savedViewportParams = {
      zoom: map.getZoom(),
      center: map.getCenter(),
      pitch: map.getPitch(),
      bearing: map.getBearing()
    };

      return savedViewportParams;
  }

  function lampDataFiltered() {
    return getLampData
        .filter(el => el.isHide === true)
        .map((item) => {
          return {
            id: item.id,
            isHide: item.isHide,
          };
        });
  }
  const onDocumentBodyClick = (e) => {
    if (
      e.target.id === "screenShot_icon" ||
      e.target.id === "save_us_screenShot" ||
      e.target.id === "new_screenShot"
    )
      return;
    let data = handleReturnStatesData();
    setScreenShotIntermediateDataST(stringify(data));
    setScreenShotButtonStateST(true);
  };
  const handleCreateSlide = (data) =>{
    return createSlideAPI(
                topicNavigation.current?.title ||
                lessonNavigationData.current?.title,
                data,
               null,
               {
                 topicId:searchParams.get(`topicID`),
                 lessonId:searchParams.get(`lessonID`),
               }
            )
  }
  const handleEditSlide = (data) =>{
    const body = {
      state:stringify(data),
      imageUrl:data.imageUrl,
      mediaId:data.mediaId
    }
    return editSlideAPI(screenSlideID,body)
  }
  const handleCaptureClick = async (blob,data,type)=>{
   return await uploadImage(blob)
        .then(resp=>{
          isScreenShot && setScreenShotState(!screenShotState);
          data.imageUrl = resp?.mediaInfo?.optimized
          data.mediaId = resp?.id
        })
        .then(()=>{
          if(type === 'edit') return handleEditSlide(data)
          handleCreateSlide(data)
        });
  };

  const handleOverlayBody = (type) => {
    let data = handleReturnStatesData();
    setScreenShotIntermediateDataST(stringify(data));
    setScreenShotSendDataST(stringify(data));

    setTimeout(() => {
      setScreenShotButtonClickST(!getScreenShotButtonClickST);
    }, 1000);
    if(type === 'presentation'){
      const elementId =
          isReadMoreArticle && !galleryIndex && !isGalleryMode ? 'article-read-more' :
              ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && !isReadMoreArticle ? 'gallery-section' :
                  ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && isReadMoreArticle ? 'article-gallery' :
                      'map';
        handleCaptureTypeBlob((blob)=>handleCaptureClick(blob,data), elementId, isGalleryMode)
    }else{
      createScreenShotAPI(
          topicNavigation.current?.title ||
          lessonNavigationData.current?.title,
          data,
          searchParams.get(`topicID`),
          searchParams.get(`lessonID`),
      );
    }
    overlay.current.style = "display:block";
    setTimeout(() => {
      overlay.current.style = "display:none";
    }, 500);

    setScreenShotState(false);
  };

  const [screenShotState, setScreenShotState] = useState(false);

  const onScreenShotEdit = (type) => {
    let data = handleReturnStatesData();
    if(type === 'presentation-edit'){
      const elementId = isReadMoreArticle && !galleryIndex && !isGalleryMode ? 'article-read-more' :
              ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && !isReadMoreArticle ? 'gallery-section' :
                  ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && isReadMoreArticle ? 'article-gallery' :
                      'map';
      handleCaptureTypeBlob((blob)=>handleCaptureClick(blob,data, 'edit'), elementId, galleryIndex)
    }else {
      editScreenDataAPI(
          parse(getScreenShotSendDataST).id,
          getTopicStaticTitleST,
          data,
          null,
          searchParams.get(`topicID`),
          searchParams.get(`lessonID`),
      );
      setTimeout(() => {
        setScreenShotButtonClickST(!getScreenShotButtonClickST);
      }, 1000);
    }
    setScreenShotIntermediateDataST(stringify(data));
    setScreenShotSendDataST(stringify(data));



    overlay.current.style = "display:block";
    setTimeout(() => {
      overlay.current.style = "display:none";
    }, 500);
    setScreenShotState(false);
  };
  useEffect(() => {
    document.body.addEventListener("mouseup", onDocumentBodyClick);

    document
        .getElementsByClassName("timeline__content")[0]
        .addEventListener("click", onDocumentBodyClick);

    return () => {
      document.body.removeEventListener("mouseup", onDocumentBodyClick);
      const timeLineContent = document
          .getElementsByClassName("timeline__content")[0];
      if (timeLineContent) {
        timeLineContent.removeEventListener("click", onDocumentBodyClick);
      }
    };
  }, [props]);


  if(type === 'presentation-article'){
    return (
        <>
          <button
              style={{marginTop: white && '6px', marginRight: isReadMoreArticle && !isGalleryMode && '40px'}}
              ref={contentRef}
                  data-is-loading={globalLoading}
          onClick={()=>{
            handleOverlayBody('presentation')
          }}>
           <ArticlePresentationScreenshot white={white}/>
          </button>
          <div ref={overlay} className={"body-overlay-shot"}></div>
        </>
    )
  }
  if(type === 'presentation'){
    return (
        <>
          <button ref={contentRef}
                  data-is-loading={globalLoading}
          onClick={()=>{
            handleOverlayBody('presentation')
          }}>
            <img src={AttachIcon}/>
          </button>
          <div ref={overlay} className={"body-overlay-shot"}></div>
        </>
    )
  }
  if(type === 'presentation-edit'){
    return (
        <>
          <button onClick={()=>onScreenShotEdit('presentation-edit')}>
            <img src={SaveSlideIcon} alt='Save Slide'/>
          </button>
          <div ref={overlay} className={"body-overlay-shot"}></div>
        </>
    )
  }
  return (
    <div
      style={{
        position: "relative",
        display:'flex',
        gap:"10px",
        height:'auto',
      }}
    >
      {!article && <ScreenShotNotification/>}
      <button
        id="screenShot_icon"
        onClick={() => {
          isScreenShot && setScreenShotState(!screenShotState);
          !isScreenShot && handleOverlayBody();
        }}
        className="right-side__search-btn main__tab-btn screenshot-btn"
        type="button"
        style={{
          borderRadius: "10px",
          backgroundColor: white ? "transparent" : "#fff",
          opacity: !isPresentation ? disable === "none" || disable ? 1 : 0.7 : 0.7,
        }}
        disabled={!isPresentation  ? disable === "none" || disable ? false : true : true}
      >
        <span className="main__tab-icon icon-wrapper screenshot-icon">
          <img src={white ? ScreenShotIconWhite : ScreenShotIcon} />
        </span>
      </button>

      {screenShotState && isScreenShot && (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px 15px",
            borderRadius: "10px",
            marginTop: "20px",
            position: "absolute",
            width: "fit-content",
            display: "flex",
            left: isGalleryMode? '-105%' : galleryFullScreen ? '10%' : '50%',
            top: isReadMoreArticle || white ? "65%" : "100%",
            transform: "translateX(-50%)",
          }}
        >
          <div
              style={{left: isGalleryMode ? '70%' : galleryFullScreen ? "52%" : "45.5%",
                width: (isGalleryMode || galleryFullScreen) && 'calc(15px / 0.7)',
                height: (isGalleryMode || galleryFullScreen) &&'calc(15px / 0.7)'}}
              className="tooltip"></div>
          <button
            style={{
              backgroundColor: "#fff",
              border: "1px solid gray",
              width: "140px",
              fontSize: "12px",
              padding: !isReadMoreArticle && "6px",
              marginRight: "4px",
              cursor: "pointer",
              borderRadius: "10px",
              fontWeight: 500,
              height: isReadMoreArticle && "20px"
            }}
            id="new_screenShot"
            onClick={() => {
              handleOverlayBody();
            }}
          >
            Ստեղծել նորը
          </button>
          <button
            style={{
              backgroundColor: "#fff",
              border: "1px solid gray",
              width: "140px",
              fontSize: "12px",
              padding: !isReadMoreArticle && "6px",
              marginLeft: "4px",
              cursor: "pointer",
              borderRadius: "10px",
              fontWeight: 500,
              height: isReadMoreArticle && "20px"
            }}
            id="save_us_screenShot"
            onClick={() => {
              onScreenShotEdit();
            }}
          >
            Պահպանել
          </button>
        </div>
      )}
      <div ref={overlay} className={"body-overlay-shot"}></div>
    </div>
  );
};

const mapStateTopProps = (state) => ({
  getLineDrawToolboxState: getLineDrawToolboxState(state),
  getMapboxDrawToolboxGeoJSONState: getMapboxDrawToolboxGeoJSONState(state),
  topics: getTopicsData(state),
  timeLineItems: getTimeLineItemsData(state),
  menuExpend: getMenuExpendData(state),
  mapStateSingleData: getMapStateSingleData(state),
  getOpenCategoryID: getOpenCategoryID(state),
  mapRequirement: getMapRequirementData(state),
  mapRequirements: getMapRequirementsData(state),
  hints: getHintsData(state),
  quizPopup: getQuizPopupData(state),
  getCategoryArticleClickedId: getCategoryArticleClickedId(state),
  filteredCategories: getFilteredCategoriesData(state),
  getLampData: getLampData(state),
  getLampSortedTooltipState: getLampSortedTooltipState(state),
  getMapBounceData: getMapBounceData(state),
  getLampSelectedId: getLampSelectedId(state),
  getMapStyledDarkId: getMapStyledDarkId(state),
  painterStart: getPainterStartData(state),
  getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
  getPainterColor: getPainterColor(state),
  getPainterColorData: getPainterColorData(state),
  getPainterSize: getPainterSize(state),
  getPainterSizeData: getPainterSizeData(state),
  getColorPickerShowState: getColorPickerShowState(state),
  getRulerClickedState: getRulerClickedState(state),
  getEraserClickedState: getEraserClickedState(state),
  getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
  getRulerGeoJsonDataST: getRulerGeoJsonDataST(state),
  getCommentToolboxState: getCommentToolboxState(state),
  getStickyNotesClickedState: getStickyNotesClickedState(state),
  getCommentToolboxMarkersState: getCommentToolboxMarkersState(state),
  getStickyNotesMarkersState: getStickyNotesMarkersState(state),
  getTextToolboxState: getTextToolboxState(state),
  getTextToolboxMarkersState: getTextToolboxMarkersState(state),
  getNewAngle: getNewAngle(state),
  getNewRotate: getNewRotate(state),
  getCompassCoordinates: getCompassCoordinates(state),
  getRulerPopupShowST: getRulerPopupShowST(state),
  getTimeLineZoomST: getTimeLineZoomST(state),
  getTimeLineZoomIndexST: getTimeLineZoomIndexST(state),
  getTimeLineMoveCountST: getTimeLineMoveCountST(state),
  getTimeLineCursorStatusST: getTimeLineCursorStatusST(state),
  getTimeLineEventDataST: getTimeLineEventDataST(state),
  getTimeLineMovePositionST: getTimeLineMovePositionST(state),
  getTimeLineMoveStatusST: getTimeLineMoveStatusST(state),
  getTimeTineZoomLevelST: getTimeTineZoomLevelST(state),
  timelineExtend: getTimeLineExpendData(state),
  getMapCenterCoordinatesST: getMapCenterCoordinatesST(state),
  getScreenShotButtonClickST: getScreenShotButtonClickST(state),
  selectedLesson: getSelectedLessonId(state),
  articleData: getArticlesData(state),
  menu: getMenuData(state),
  getScreenShotIntermediateDataST: getScreenShotIntermediateDataST(state),
  getTimeLineMovieDataST: getTimeLineMovieDataST(state),
  getScreenShotSendDataST: getScreenShotSendDataST(state),
  getTopicStaticTitleST: getTopicStaticTitleST(state),
  getTimeLineHeightST: getTimeLineHeightST(state),
  getLampModalState: getLampModalState(state),
  getLampModalStateIndex: getLampModalStateIndex(state),
  filteredLegends: getFilteredLegendsData(state),
  getSubjectsSettingsData: getSubjectsSettingsData(state),
  getMapMarkerInfo: getMapMarkerInfo(state),
  topicNavigation: getTopicNavigationData(state),
  lessonNavigationData: getLessonNavigationData(state),
  baseMapGroupIdST: getBaseMapGroupIdST(state),
  baseMapIsLight: getBaseMapIsLight(state),
});

const mapDispatchToProps = {
  createScreenShotAPI,
  setScreenShotSendDataST,
  setScreenShotIntermediateDataST,
  setScreenShotButtonClickST,
  setScreenShotButtonStateST,
  editScreenDataAPI,
  createSlideAPI,
  editSlideAPI,
  getPresentationAPI
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(MapScreenShotButton);
