import React, {useEffect, useRef, useState} from 'react';
import CloseIcon from '../../../assets/imgs/PaintBar/close-icon.svg';
import DeleteIcon from '../../../assets/imgs/PaintBar/delete-trash-icon.svg';
import BackgroundColorIcon from '../../../assets/imgs/PaintBar/sticky-note-background-color-icon.svg';
import Green from '../../../assets/imgs/PaintBar/StickyNotesPaths/green.svg';
import LightBlue from '../../../assets/imgs/PaintBar/StickyNotesPaths/lightBlue.svg';
import Brown from '../../../assets/imgs/PaintBar/StickyNotesPaths/brown.png';
import Blue from '../../../assets/imgs/PaintBar/StickyNotesPaths/blue.svg';
import Yellow from '../../../assets/imgs/PaintBar/StickyNotesPaths/yellow.svg';
import {ResizeIcon} from '../../../assets/imgs/PaintBar/resize'
import {RotateIcon} from '../../../assets/imgs/PaintBar/rotate'
import "./style.css";
import {color_picker_data} from "../../../utils/color_picker_data";
import Rotatable from 'react-rotatable';
import 'react-resizable/css/styles.css';
import { ResizableBox } from 'react-resizable';
import MarkerIcon from "./MarkerIcon";
import {setStickyNotesMarkersLoadingState, setStickyNotesMarkersState} from "../../../store/actions/mapStateAction";
import {
    getRulerClickedState,
    getStickyNotesClickedState,
    getStickyNotesMarkersLoadingState,
    getStickyNotesMarkersState
} from "../../../store/selectors";
import {
    setCommentToolboxState, setDrawerToolboxClickedState,
    setEraserClickedState, setLineDrawToolboxState,
    setRulerClickedState,
    setStickyNotesClickedState
} from "../../../store/actions/painterStart";
import {connect} from "react-redux";

const StickyNotesEditPopup = (props) => {
    const {
        getStickyNotesMarkersState,
        setStickyNotesMarkersState,
        description,
        color,
        background,
        markerWidth,
        markerHeight,
        angle,
        id,
        isEdited,
        isMaximize,
        left,
        top
    } = props
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const popupRef = useRef()
    const textareaRef = useRef()
    const [isEditing, setIsEditing] = useState(isEdited);
    const [isDragging, setIsDragging] = useState(false);
    const [isDraggingStarted, setIsDraggingStarted] = useState(false);
    const [position, setPosition] = useState({ left, top });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [isMaximized, setIsMaximized] = useState(isMaximize);
    const [text, setText] = useState('');
    const [textColor, setTextColor] = useState('#000000');
    const [commentColor, setCommentColor] = useState(color || '#FFF8A7');
    const [isOpenColors, setIsOpenColors] = useState(false);
    const [isOpenPatternPicker, setIsOpenPatternPicker] = useState(false);
    const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [width, setWidth] = useState(+markerWidth);
    const [height, setHeight] = useState(+markerHeight);
    const [minConstraints, setMinConstraints] = useState([180, 220]);
    const [maxConstraints, setMaxConstraints] = useState([360, 440]);
    const [rgbaColor, setRgbaColor] = useState('rgba(230, 223, 150, 0.92)')
    const [scrollerColor, setScrollerColor] = useState('#FFF8A7')
    const [uIBackground, setUIBackground] = useState(background)
    const [iconPosition, setIconPosition] = useState({ top: 0, left: 0 });
    const beforeStyle = `
    .sticky-note-editor-${id}::before {
    bottom: -23px;
    border: 12px solid transparent;
    border-right-color: ${rgbaColor};
    border-bottom-color: ${rgbaColor};
    left: 0;
    transform: rotate(270deg);
    z-index: 2;
    background-size: cover;
    }
    .sticky-note-editor-${id}::after {
    background: ${uIBackground};
    position: absolute;
    height: 24px;
    width: calc(100% - 23px);
    bottom: -23px;
    right: 0;
    }
    .sticky-note-editor-${id} textarea::-webkit-scrollbar-track {
      background-color: ${scrollerColor};
    }`
    const [colorsForStickyNotesColorPicker, setColorsForStickyNotesColorPicker] = useState([
        { id: 1, color: "#000000", status: 0 },
        { id: 2, color: "#2C476C", status: 0 },
        { id: 3, color: "#157EFA", status: 0 },
        { id: 4, color: "#53D669", status: 0 },
        { id: 5, color: "#FFF8A7", status: 1 },
        { id: 6, color: "#FC3142", status: 0 },
        { id: 7, status: 0 },
    ]);
    const patternsPicker = [
        { id: 1, path: Brown, status: 0, rgb: {r: 210, g: 185, b: 155} },
        { id: 2, path: Green, status: 0, rgb: {r: 190, g: 246, b: 139} },
        { id: 3, path: LightBlue, status: 0, rgb: {r: 101, g: 244, b: 221} },
        { id: 4, path: Blue, status: 0, rgb: {r: 112, g: 183, b: 241} },
        { id: 5, path: Yellow, status: 0, rgb: {r: 255, g: 248, b: 167} },
    ];
    function hexToRgb(hex) {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return { r, g, b };
    }

    function blendColors(rgb1, rgb2, alpha1, alpha2) {
        const r = Math.round(rgb1.r * alpha1 + rgb2.r * alpha2 * (1 - alpha1));
        const g = Math.round(rgb1.g * alpha1 + rgb2.g * alpha2 * (1 - alpha1));
        const b = Math.round(rgb1.b * alpha1 + rgb2.b * alpha2 * (1 - alpha1));
        return `rgba(${r},${g},${b},${alpha1 + alpha2 * (1 - alpha1)})`;
    }
    const changeColorsForStickyNotesColorPicker = (colorHEX) => {
        const updatedColors = colorsForStickyNotesColorPicker.map(color => {
            if (color.color === colorHEX) {
                return { ...color, status: 1 };
            } else {
                return { ...color, status: 0 };
            }
        });
        setColorsForStickyNotesColorPicker(updatedColors);
    };

    const handleTextareaText = (e) => {
        setText(e.target.value);
    };

    const setStickyInfoParamByID = (id, param, value) => {
        const index = getStickyNotesMarkersState.findIndex(marker => marker.id === id);
        if (index !== -1) {
            const newMarkers = [...getStickyNotesMarkersState];
            newMarkers[index][param] = value;
            setStickyNotesMarkersState(newMarkers)
        } else {
            console.error(`Marker with id ${id} not found.`);
        }
    };

    const handleSave = (id, text, background, markerColor, isMaximized, angle, width, height, top, left) => {
        setStickyInfoParamByID(id, 'id', id);
        setStickyInfoParamByID(id, 'description', text);
        setStickyInfoParamByID(id, 'markerColor', markerColor ? markerColor : "#FFF8A7");
        setStickyInfoParamByID(id, 'background', background ? background : "#FFF8A7");
        setStickyInfoParamByID(id, 'angle', angle ? angle.toString() : 0);
        setStickyInfoParamByID(id, 'markerWidth', width ? width.toString() : 180);
        setStickyInfoParamByID(id, 'markerHeight', height ? height.toString() : 220);
        setStickyInfoParamByID(id, 'isMaximized', isMaximized);
        setStickyInfoParamByID(id, 'isEdited', false);
        setStickyInfoParamByID(id, 'top',top) ;
        setStickyInfoParamByID(id, 'left', left)
    }
    const handleSaveWrapper = () => {
        setScrollerColor(commentColor)
        handleSave(id, text, uIBackground, commentColor, isMaximized, rotation, width, height, position.top, position.left);
    };

    const handleDelete = (id) => {
        const note = getStickyNotesMarkersState.find(note => note.id === id)
        if(!note.isMaximized) return
        const filteredData = getStickyNotesMarkersState.filter(note => note.id !== id)
        setStickyNotesMarkersState(filteredData)
    }

    const handleCloseWrapper = () => {
        const currentText = popupRef.current.querySelector('textarea')?.value?.trim();
        setIsEditing(false);
        setIsOpenPatternPicker(false);
        setIsOpenColors(false);
        setIsOpenColorPicker(false);
        textareaRef.current?.blur();
        !currentText.length && handleDelete(id);
    };

    const toggleColorsDropdown = () => {
        if (isOpenColorPicker && isOpenColors) {
            setIsOpenColorPicker(false);
        }
        setIsOpenColors(!isOpenColors);
        setIsOpenPatternPicker(false)
    };

    const togglePatternsDropdown = () => {
        setIsOpenColors(false);
        setIsOpenColorPicker(false);
        setIsOpenPatternPicker(!isOpenPatternPicker);
    };

    const isDarkColor = (color) => {
        let r, g, b;
        if (color.startsWith('#')) {
            const hex = color.slice(1);
            r = parseInt(hex.substr(0, 2), 16);
            g = parseInt(hex.substr(2, 2), 16);
            b = parseInt(hex.substr(4, 2), 16);
        } else {
            [r, g, b] = color.match(/\d+/g).map(Number);
        }

        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
        return luminance < 128;
    };

    const toggleTextColor = (color) => {
        if (isDarkColor(color)) {
            setTextColor('#fff');
        } else {
            setTextColor('#000');
        }
    }
    const setRgba = (color) => {
        const newColorRgb = hexToRgb(color);
        const blendedColor = blendColors(newColorRgb, { r: 0, g: 0, b: 0 }, 0.9, 0.2);
        setScrollerColor(blendedColor);
        setRgbaColor(blendedColor)
    }
    const colorSelectHandler = (e, selectedColor) => {
        e.preventDefault();
        e.stopPropagation();
        if (selectedColor.id === colorsForStickyNotesColorPicker.length) {
            setIsOpenColorPicker(!isOpenColorPicker);
            return;
        }
        toggleTextColor(selectedColor.color)
        setCommentColor(selectedColor.color);
        setUIBackground(selectedColor.color)
        setRgba(selectedColor.color)
        changeColorsForStickyNotesColorPicker(selectedColor.color);
    };
    const pathSelectHandler = (e, selectedPath) => {
        e.preventDefault();
        e.stopPropagation();
        setTextColor('#000')
        setCommentColor('#FFF8A7');
        const colorRgb = selectedPath.rgb
        const blendedColor = blendColors(colorRgb, { r: 0, g: 0, b: 0 }, 0.9, 0.2);
        setUIBackground(`url(${selectedPath.path})`);
        setScrollerColor(colorRgb);
        setRgbaColor(blendedColor);
        changeColorsForStickyNotesColorPicker(blendedColor);
    };

    const handleMaximizeOrMinimize = (e) => {
        if (isDraggingStarted) {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingStarted(false);
            textareaRef.current?.blur();
            return;
        }
        setIsMaximized(!isMaximized)
    }

    const handleClickOutside = (e) => {
        if(!isMaximized) return
        const currentText = popupRef.current.querySelector('textarea')?.value?.trim();
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setIsEditing(false);
            setIsOpenPatternPicker(false)
            setIsOpenColors(false)
            setIsOpenColorPicker(false)
            !currentText?.length && handleDelete(id);
        }
    };

    const handleRotate = (event, element, currentAngle) => {
        setRotation(currentAngle - 143)
    };

    const handleClickInside = (e) => {
        if (isDraggingStarted || e.target.closest('.markerIcon') || e.target.closest('.popUp-close') || (!isMaximized && e.target.closest(''))) {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingStarted(false);
            textareaRef.current?.blur();
            return;
        }
        setIsEditing(true);
    };
    const handleResize = (e, {size}) => {
        e.preventDefault()
        e.stopPropagation()
        setHeight(size.height)
        setWidth(size.width)
    }

    const handleMouseDown = (e) => {
        if ((e.target.className === 'react-resizable-handle react-resizable-handle-se') ||
            (isEditing && e.target.className === 'sticky_textarea')) {
            return;
        }
        const component = popupRef.current;
        const rect = component.getBoundingClientRect();
        const rectLeft = isMaximized ? rect.left : rect.left - 80
        const rectTop = isMaximized ? rect.top : rect.top + 20
        const offsetX = e.clientX - rectLeft;
        const offsetY = e.clientY - rectTop;

        setOffset({ x: offsetX, y: offsetY });
        setIsDragging(true);
        setIsDraggingStarted(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const component = popupRef.current;
        const rect = component.getBoundingClientRect();

        const newLeft = e.clientX - offset.x;
        const newTop = e.clientY - offset.y;

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const maxLeft = viewportWidth - rect.width;
        const maxTop = viewportHeight - rect.height - 135;

        setPosition({
            left: Math.max(50, Math.min(newLeft, maxLeft)),
            top: Math.max(50, Math.min(newTop, maxTop)),
        });
        setTimeout(() => {setIsDraggingStarted(true)}, 100)
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const svgToDataURL = (svg) => {
        const base64 = window.btoa(svg);
        return `data:image/svg+xml;base64,${base64}`;
    };

    const changeCursorsRotation = () => {
        const resizeElements = document.querySelectorAll('.react-resizable-handle-se');
        const rotateElements = document.querySelectorAll('.rotate-handle');
        if (resizeElements.length > 0 || rotateElements.length > 0) {
            const resizeCursor = svgToDataURL(ResizeIcon(rotation));
            const rotateCursor = svgToDataURL(RotateIcon(rotation));
            resizeElements.forEach(handleElement => {
                handleElement.style.cursor = `url(${resizeCursor}), auto`;
            });
            rotateElements.forEach(handleElement => {
                handleElement.style.cursor = `url(${rotateCursor}), auto`;
            });

        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setMinConstraints([window.innerWidth / 10, window.innerHeight / 4.36]);
            setMaxConstraints([window.innerWidth / 5, window.innerHeight / 2.18]);
        };
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        changeColorsForStickyNotesColorPicker(commentColor);
    }, [commentColor]);

    useEffect(() => {
        if(background) {
            let rgb;
            if(background.includes('url')) {
                const strippedUrl2 = background.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
                rgb = patternsPicker.find(el => el.path === strippedUrl2).rgb
            } else {
                rgb = hexToRgb(background)
            }
            const blendedColor = blendColors(rgb, { r: 0, g: 0, b: 0 }, 0.9, 0.2);
            setRgbaColor(blendedColor);
            toggleTextColor(blendedColor)
        }
    }, [])

    useEffect(() => {
        if (description) {
            setText(description);
        }
        if(background) {
            setUIBackground(background)
        }
        if(markerWidth && markerHeight) {
            if(markerWidth > windowWidth / 5) {
                setWidth( windowWidth / 5)
            }
            if (markerHeight > windowHeight / 2.18) {
                setHeight(windowHeight / 2.18)
            }
            if (markerWidth < windowWidth / 10) {
                setWidth(windowWidth / 10)
            }
            if (markerHeight < windowHeight / 4.36) {
                setHeight(windowHeight / 4.36)
            }
        } else {
            setHeight(220)
            setWidth(180)
        }
        setIsMaximized(isMaximize)
        if(angle) {
            setRotation(angle)
        }
        if(left > windowWidth - width) {
            const calcLeft = windowWidth - width - 20
            setPosition({top, left: calcLeft})
        }
        if(top > windowHeight - height - 135) {
            const calcTop = windowHeight - height - 155
            setPosition({top: calcTop, left})
        }
        changeCursorsRotation()
    }, []);

    useEffect(() => {
        !isDragging && handleSaveWrapper();
    }, [commentColor, uIBackground, height, width, isDragging, isMaximized, rotation, text]);

    useEffect(() => {
        const centerX = width / 2;
        const centerY = height / 2;
        const iconX = centerX + 10;
        const iconY = -4.5001;
        const angleRad = rotation * (Math.PI / 180);
        const rotatedX = centerX + (iconX - centerX) * Math.cos(angleRad) - (iconY - centerY) * Math.sin(angleRad) +
            position.left;
        const rotatedY = centerY + (iconX - centerX) * Math.sin(angleRad) + (iconY - centerY) * Math.cos(angleRad) +
            position.top;
        setIconPosition({
            top: rotatedY,
            left: rotatedX
        });
    }, [position, width, height, rotation]);

    return ( isMaximized ?
                <div
                    id={`sticky_note_container-${id}`}
                    className='sticky_note_container'
                    onClick={() => !isMaximized && null}
                    onMouseDown={handleMouseDown}
                    style={{
                        left: `${position.left}px`,
                        top: `${position.top}px`,
                        cursor: isDragging ? 'grabbing' : isMaximized ? 'grab' : 'default',
                        width: width,
                        height: height,
                        transform: `rotate(${(isOpenColors || isOpenPatternPicker) ? 0 : rotation}deg)`,
                    }}
                    ref={popupRef}>
                    <style>{beforeStyle}</style>
                    <Rotatable
                        onRotateStop={changeCursorsRotation}
                        onRotate={handleRotate}>
                        <ResizableBox
                            onMouseEnter={changeCursorsRotation}
                            width={width} height={height} minConstraints={minConstraints}
                            maxConstraints={maxConstraints}
                            className={'rotate-zero'}
                            onResize={handleResize}>
                            <div className={'sticky_note'}
                                 onClick={handleClickInside}>
                                {isOpenColors &&
                                    <div className={'color-picker-popup'}>
                                        {colorsForStickyNotesColorPicker.map ((color, i) => (
                                            <div
                                                key={i}
                                                className="painter_tools_color_data_item"
                                                onClick={(e) => colorSelectHandler (e, color)}
                                            >
                                                {i === colorsForStickyNotesColorPicker.length - 1 ? (
                                                    <div className={"color_circle"}>
                                                        <svg
                                                            style={{
                                                                background: "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                                                borderRadius: "50%",
                                                                height: 20,
                                                                stroke: color.status ? '#3064ab' : '',
                                                                strokeWidth: color.status ? "2" : "0",
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className={"available-color"}>
                                                        <svg
                                                            style={{
                                                                stroke: color.status ? '#3064ab' : color?.color,
                                                                strokeWidth: color.status ? "2" : "0",
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox={color.status ? "0 0 20 20" : "0 0 24 24"}
                                                            fill={color?.color}
                                                        >
                                                            <circle
                                                                cx={color.status ? "10" : "12"}
                                                                cy={color.status ? "10" : "12"}
                                                                r={color.status ? "9" : "11.5"}
                                                                stroke={color?.color}
                                                            />
                                                            <circle
                                                                cx={color.status ? "10" : "12"}
                                                                cy={color.status ? "10" : "12"}
                                                                r={color.status ? "8" : "8.5"}
                                                                fill={color?.color}
                                                            />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                }
                                {isOpenPatternPicker && (
                                    <div className={'pattern-picker-popup'} onClick={(e) => {
                                        e.preventDefault ();
                                        e.stopPropagation ();
                                    }}>
                                        {patternsPicker.map ((pattern, i) => (
                                            <div
                                                key={pattern.id}
                                                className="painter_tools_path_data_item"
                                                onClick={(e) => pathSelectHandler (e, pattern)}
                                            >
                                                <img
                                                    src={pattern.path}
                                                    alt={`Pattern ${i + 1}`}
                                                    className="pattern-image"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {isOpenColorPicker && (
                                    <div
                                        className="sticky_note_tools_color_item_container"
                                        onClick={(e) => {
                                            e.preventDefault ();
                                            e.stopPropagation ();
                                        }}
                                    >
                                        {color_picker_data.map ((color, i) => (
                                            <div
                                                key={i}
                                                className="comment_box_tools_color_item"
                                                style={{backgroundColor: color}}
                                                onClick={() => {
                                                    setCommentColor (color);
                                                    changeColorsForStickyNotesColorPicker (null);
                                                    setIsOpenColorPicker (false);
                                                    setIsOpenColorPicker (false);
                                                    toggleTextColor (color)
                                                    setUIBackground (color)
                                                    setRgba (color)
                                                }}
                                            ></div>
                                        ))}
                                        <span></span>
                                    </div>
                                )}
                                {isEditing && <div className={'edit-popup-header'}>
                                    <div className={'popUp-actions'}>
                                        <div className={'color-picker-wrapper'}>
                                            <div className={'color-picker-comment-box'}>
                                                <div className='comment-box-popup-container_color-picker'
                                                     onClick={toggleColorsDropdown}>
                                                <span className={"current-picked-color-comment-box"}
                                                      onClick={toggleColorsDropdown}>
                                                    <svg
                                                        style={{stroke: '#3064ab'}}
                                                        strokeWidth="3"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill={commentColor}
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="10.5"
                                                            stroke={commentColor}
                                                        />
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="9"
                                                            fill={commentColor}
                                                        />
                                                    </svg>
                                                </span>
                                                </div>
                                            </div>
                                            <div className='background-color-changer-icon'
                                                 onClick={togglePatternsDropdown}>
                                                <img src={BackgroundColorIcon}/>
                                            </div>
                                        </div>
                                        <span className={'popUp-delete'} onClick={() => handleDelete (id)}>
                                        <img src={DeleteIcon} alt=""/>
                                    </span>
                                        <span className={'popUp-close'} onClick={handleCloseWrapper}>
                                        <img src={CloseIcon} alt=""/>
                                    </span>
                                    </div>
                                </div>}
                                {!isEditing && <div className={'markerIcon'}>
                                    <MarkerIcon onClick={handleMaximizeOrMinimize} color={commentColor}/>
                                </div>}
                                <div
                                    style={{background: uIBackground}}
                                    className={`sticky-note-editor sticky-note-editor-${id}`}>
                                <textarea
                                    className='sticky_textarea'
                                    ref={textareaRef}
                                    style={{color: textColor, background: uIBackground}}
                                    maxLength="2000"
                                    rows="4" cols="50"
                                    autoFocus={isEditing}
                                    value={text}
                                    onInput={handleTextareaText}/>
                                </div>
                            </div>
                        </ResizableBox>
                    </Rotatable>
                </div> :
                <div
                    onMouseDown={handleMouseDown}
                    ref={popupRef}
                    style={{
                        position: 'absolute',
                        left: `${iconPosition.left}px`,
                        top: `${iconPosition.top}px`,
                        zIndex: 1001,
                        transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
                    }}
                    onClick={handleMaximizeOrMinimize} className={'markerIcon'}>
                    <MarkerIcon onClick={handleMaximizeOrMinimize} color={commentColor}/>
                </div>
    )
};
const mapStateToProps = (state) => ({
    getStickyNotesClickedState: getStickyNotesClickedState(state),
    getStickyNotesMarkersState: getStickyNotesMarkersState(state),
    getRulerClickedState: getRulerClickedState(state),
    getStickyNotesMarkersLoadingState: getStickyNotesMarkersLoadingState(state),
});

const mapDispatchToProps = {
    setStickyNotesClickedState,
    setEraserClickedState,
    setRulerClickedState,
    setStickyNotesMarkersState,
    setStickyNotesMarkersLoadingState,
    setLineDrawToolboxState,
    setCommentToolboxState,
    setDrawerToolboxClickedState
};

export default connect(mapStateToProps, mapDispatchToProps)(StickyNotesEditPopup);

